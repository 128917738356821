export const UsuarioConfig = {
  new:{
    tab:'Nuevo usuario',
    action:'Guardar',
    msg:'El usuario fue agregado exitosamente.',
    err:'Error al agregar el usuario.'
  },
  edit:{
    tab:'Modificar usuario',
    action:'Guardar',
    msg:'El usuario fue modificado exitosamente.',
    err:'Error al modificar el usuario.'
  }
}

export const ColoniaConfig = {
  new:{
    tab:'Nuevo colonia',
    action:'Guardar',
    msg:'El colonia fue agregado exitosamente.',
    err:'Error al agregar la colonia.'
  },
  edit:{
    tab:'Modificar colonia',
    action:'Guardar',
    msg:'El colonia fue modificado exitosamente.',
    err:'Error al modificar la colonia.'
  }
}

export const InmobiliariaConfig = {
  new:{
    tab:'Nueva inmobiliaria',
    action:'Guardar',
    msg:'La inmobiliaria fue agregada exitosamente.',
    err:'Error al agregar la inmobiliaria.'
  },
  edit:{
    tab:'Modificar inmobiliaria',
    action:'Guardar',
    msg:'La inmobiliaria fue modificada exitosamente.',
    err:'Error al modificar la inmobiliaria.'
  }
}